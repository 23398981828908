import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
moment.locale('es');
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExportExcel {

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    let data = json.map(data => ({
          'id': data.id,
          'Usuario': data.establecimiento.user.name,
          'Creado por': data.establecimiento.user.userPadre? data.establecimiento.user.userPadre.name: '',
          'Establecimiento': data.establecimiento.nombre,
          'Localidad': data.establecimiento.localidadCercana,
          'Latitud': data.establecimiento.latitud,
          'Longitud': data.establecimiento.longitud,

          'Nro. Lote': data.numeroLote,
          'Fecha': moment(data.fechaSiembra).utc().format('DD/MM/YYYY'),
          'Cultivo': data.tipoCultivo,
          'Hectáreas': data.hectareas,
          'Insumo': data.insumo,
          'Cantidad': data.cantidadInsumo,
          'Estado': data.estado,
        }))

    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_exported'+ EXCEL_EXTENSION);
  }

}
