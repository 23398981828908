import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './app-toolbar.component.html',
  styleUrls: ['app-toolbar.component.scss']
})
export class AppToolbarComponent {
  @Input() title : string;
  constructor() {
    this.title = null
  }

}
