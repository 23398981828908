<app-toolbar title="Tablero de control"></app-toolbar>
<form class="filter-section row" novalidate [formGroup]="filterForm" (ngSubmit)="onSearch()">
  <div class="col-md-5">
    <div class="form-group col-md-4" *ngIf="rolUser === 'CORPORATE' ">
      <label for="empresa">Empresa</label>
      <select id="empresa" formControlName="empresa" class="form-control" (change)="getDistribuidores()">
        <option *ngFor="let role of empresas" value={{role.id}}>{{role.name}}</option>
      </select>
    </div>
    <div class="form-group col-md-4" *ngIf="rolUser === 'COMPANY' || rolUser ==='CORPORATE' ">
      <label for="distribuidor">Distribuidor</label>
      <select id="distribuidor" formControlName="distribuidor" class="form-control" (change)="getProductores()">
        <option *ngFor="let role of distribuidores" value={{role.id}}>{{role.name}}</option>
      </select>
    </div>
    <div class="form-group col-md-4"
      *ngIf="rolUser === 'COMPANY' || rolUser === 'DISTRIBUTOR' || rolUser ==='CORPORATE' ">
      <label for="productor">Productor</label>
      <select id="productor" formControlName="productor" class="form-control">
        <option *ngFor="let role of productores" value={{role.id}}>{{role.name}}</option>
      </select>
    </div>
  </div>
  <div class="col-md-7">
    <div class="row">
      <div class="col-md-12" *ngIf="(rolUser === 'COMPANY' || rolUser === 'DISTRIBUTOR') && lotes">
        <table  class="table table-bordered table-cultivos" >
          <tr>
            <th>Cultivo</th>
            <th>Campaña</th>
            <th>Insumo</th>
            <th>Cantidad Asignada</th>
            <th>Cantidad Utilizada</th>
          </tr>
          <tr *ngFor="let bolsa of getBolsas()">
            <td>{{bolsa.tipoCultivo}}</td>
            <td>{{bolsa.campania.anio}}</td>
            <td>{{bolsa.insumo}}</td>
            <td>{{bolsa.cantidad}}</td>
            <td>{{getBolsasUtilizadas(bolsa.tipoCultivo)}}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
            <div class="form-group bordes">
              <!-- <p class="mr"><b>Total de Bolsas: {{bolsas ? bolsas:0}}</b></p> -->
              <p class="mr" *ngIf="bolsasRestantes>=0"><b>Bolsas restantes: {{bolsasRestantes ? bolsasRestantes:0}}</b></p>
        
              <p class="mr"><b>Total de negocios: {{numerLotes ? numerLotes : 0}}</b></p>
              <p class="mr"><b>Negocios pendiente de gestionar: {{estadoPendiente ? estadoPendiente: 0}}</b></p>

        </div>
        
       
      </div>
    </div>

  </div>
 
  
  <div class="col-md-6">
    <div class="col-md-3">
      <div class="form-group">
        <button type="submit" class="btn btn-primary btn-block" [disabled]="!filterForm.valid">Buscar</button>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <button type="button" class="btn btn-secondary btn-block" (click)="reset()"
          [disabled]="!filterForm.valid">Limpiar</button>
      </div>
    </div>
  </div>
</form>
<div class="main-panel table-responsive" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5"
  [infiniteScrollThrottle]="100" (scrolled)="loadMore()" alwaysCallback="true">
  <table aria-describedby="Gatherers table" class="table table-striped table-bordered">
    <thead>
      <tr>
        <th scope="col" class="text-center">USUARIO</th>
        <th scope="col" class="text-center">LOCALIDAD</th>
        <th scope="col" class="text-center">ESTABLECIMIENTO</th>
        <th scope="col" class="text-center">HECTAREAS</th>
        <th scope="col" class="text-center">CULTIVO</th>
        <th scope="col" class="text-center">CANT. INSUMO</th>
        <th scope="col" class="text-center">INSUMO</th>


        <th scope="col" class="text-center">ESTADO</th>
        <th scope="col" class="text-center">ACCIONES</th>

      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let lote of lotes">
        <td class="text-center">{{lote.establecimiento.user.name}}</td>
        <td class="text-center">{{lote.establecimiento.localidadCercana}}</td>
        <td class="text-center">{{lote.establecimiento.nombre}}</td>
        <td class="text-center">{{lote.hectareas}}</td>
        <td class="text-center">{{lote.tipoCultivo}}</td>
        <td class="text-center">{{lote.cantidadInsumo}}</td>
        <td class="text-center">{{lote.insumo}}</td>


        <td *ngIf="lote.estado == 'APROBADO'" class="text-center aprobado">Aprobado</td>
        <td *ngIf="lote.estado == 'PENDIENTE'" class="text-center pendiente">Pendiente</td>
        <td *ngIf="lote.estado == 'RECHAZADO'" class="text-center rechazado">Rechazado</td>
        <td class="text-center accion">

          <button *ngIf="lote.estado === 'PENDIENTE'" class="btn btn-link" data-toggle="tooltip" title="Aprobar"
            (click)="openAprobarModal(aprobarModal, lote)">
            <span class="glyphicon glyphicon-ok"></span>
          </button>
          <button *ngIf="lote.estado === 'PENDIENTE'" class="btn btn-link" data-toggle="tooltip" title="Rechazar"
            (click)="openRechazarModal(rechazarModal, lote)">
            <span class="glyphicon glyphicon-remove"></span>
          </button>
          <button *ngIf="lote.estado === 'APROBADO'" class="btn btn-link boton" data-toggle="tooltip"
            title="Modificar datos" (click)="goCoverageEditForm( lote.establecimiento)">
            <span class="glyphicon glyphicon-edit"></span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #aprobarModal>
  <div class="modal-body text-center">
    <p>¿Esta seguro que desea aprobar el plan de siembra?</p>
    <button type="button" class="btn btn-default" (click)="updateState('APROBADO', lote)">Si</button>
    <button type="button" class="btn btn-primary" (click)="modalRef.hide()">No</button>
  </div>
</ng-template>
<ng-template #rechazarModal>
  <div class="modal-body text-center">
    <p>¿Esta seguro que desea rechazar el plan de siembra?</p>
    <button type="button" class="btn btn-default" (click)="updateState('RECHAZADO', lote)">Si</button>
    <button type="button" class="btn btn-primary" (click)="modalRef.hide()">No</button>
  </div>
</ng-template>