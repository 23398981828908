
import {AppToolbarComponent} from "../toolbar/app-toolbar/app-toolbar.component";
import {SidebarComponent} from "./sidebar/sidebar.component";


export const SHARED_COMPONENTS = [
  AppToolbarComponent,
  SidebarComponent
];

