import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { LoaderService } from "./loader.service";
import { AuthService } from '../auth/auth.service';


declare const Natal: any;
@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private _loading: LoaderService,
    public _authService: AuthService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.id_token;
    this._loading.setLoading(true, req.url);
    if (!token) {
      return next.handle(req);
    }

    const req1 = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    });

    return next.handle(req1).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this._loading.setLoading(false, req.url);
          if (event.status == 500) {
            let message: any = event.body.message == 'jwt expired' ? 'Sesión expirada' : 'Se produjo un error, intentelo nuevamente';
            Natal.Notification.error(
              'Error',
              message,
              false, '', 3000
            );
          }
        }
        return event;
      }),
      catchError((err: HttpErrorResponse) => {

        this._loading.setLoading(false, req.url);
        if (err.status == 401) {
          let message: any = err.error.message == 'jwt expired' ? 'Sesión expirada' : 'Se produjo un error, intentelo nuevamente';
          Natal.Notification.error(
            'Error',
            message,
            false, '', 3000
          );
          this._authService.logout();
        }
        return throwError(err);
      })
    );
  }

}
