import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
moment.locale('es');
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExportExcelSiniestro {

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    let data = json.map(data => ({
          'Productor': data.lote.establecimiento.user.name,
          'Distribuidor': data.lote.establecimiento.user.userPadre.name,
          'Empresa': data.lote.establecimiento.user.userPadre.userPadre.name,
          'id Plan de siembra': data.loteId,
          'Localidad': data.lote.establecimiento.localidadCercana,
          'Establecimiento': data.lote.establecimiento.nombre,
          'Nro. Lote': data.lote.numeroLote,
          'Contacto': data.numeroTelefono,
          'Fecha Siniestro': moment(data.fecha).utc().format('DD/MM/YYYY'),
          'Evento': data.evento,
          'Cultivo': data.lote.tipoCultivo,
          'Hectáreas afectadas': data.superficieAfectada,
          'Estado siniestro': this.estadoToString(data.estado),
          'Latitud': data.lote.establecimiento.latitud,
          'Longitud': data.lote.establecimiento.longitud,

          'Hectáreas aseguradas': data.lote.hectareas,
          'Insumo': data.lote.insumo,
          'Cantidad': data.lote.cantidadInsumo,
          'Fecha':  moment(data.lote.fechaSiembra).utc().format('DD/MM/YYYY'),
          'Estado': data.lote.estado,

        }))
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private estadoToString(estado:string): string {
    if(estado === "PENDIENTE")
      return "Pendiente de Inspección";
    else if(estado === "PROCESO_INSPECCION")
      return "En proceso de Inspección";
    else if(estado === "ASEGURADO")
      return "Aprobado";
    else if(estado === "RECHAZADO")
      return "Rechazado";
    else if(estado === "CERRADO")
      return "Cerrado";
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_exported'+ EXCEL_EXTENSION);
  }

}
