
import { NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { CallbackComponent } from './components/callback/callback.component';
import { AppComponent } from './app.component';
import {HttpTokenInterceptor} from "./core/interceptors/http.token.interceptor";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule} from '@angular/forms';
import {ExportExcel} from './utils/export-excel.service';
import { AgmCoreModule } from '@agm/core';
import {SharedModule} from "./shared";
import { SidebarModule } from 'ng-sidebar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DashboardMainComponent } from './modules/dashboard/pages/main/dashboard-main.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import {NgxPaginationModule} from 'ngx-pagination';
import { ExportExcelUser } from "./utils/export-excel-usuario.service";
import { ExportExcelSiniestro } from "./utils/export-excel-siniestro.service";

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    DashboardMainComponent,
  ],
  imports: [
    AppRoutingModule,
    NgxPaginationModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    SharedModule,
    SidebarModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAVbPWkvDEJWHpKIV9qCRzQYE3PaJy4hsY',
      libraries: ['places']
    }),
    BrowserAnimationsModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatProgressSpinnerModule,
    InfiniteScrollModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: ExportExcel },
    { provide: ExportExcelUser },
    { provide: ExportExcelSiniestro }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
