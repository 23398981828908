import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../user/user.service';

export interface Establecimiento {
  id: number,
  nombre: string,
  productor: User,
  localidadCercana: string,
  latitud: string,
  longitud: string,
  userId: number,
  lotes: Array<Lote>,

}
export interface Lote {
  id:number,
  fechaSiembra: string,
  numeroLote: number,
  hectareas:number,
  insumo:string,
  cantidadInsumo:number,
  tipoCultivo: string,
  estado: string,
  check?: boolean
  superficieAfectada?:number;
  establecimiento: Establecimiento,
  checked?:boolean

}
@Injectable({
  providedIn: 'root'
})
export class CoverageService {

  url = `${environment.api}/plansiembra`;

  constructor(public http: HttpClient) { }

  create(data: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.url, data, { observe: 'response' });
  }
  createImportarExcel(data: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.url + '/importarExcel', data, { observe: 'response' });
  }

  update(data: any): Observable<any> {
    return this.http.put(`${this.url}/${data.id}`, data);
  }

  find(id: number): Observable<any> {
    return this.http.get(this.url + '/getById/'+ id);
  }

  updateState(data: any, idEstablecimiento: number): Observable<any> {
    return this.http.put(`${this.url}/${idEstablecimiento}/changeLoteStatus`, data);
  }

  search(filter: any, pageSize, paginaActual): Observable<any> {
    let params = '?_sort=id&_order=desc';
    if(filter.dateFrom) params+= `&dateFrom=${filter.dateFrom}`;
    if(filter.dateTo) params+= `&dateTo=${filter.dateTo}`;
    if(filter.state) params+= `&status=${filter.state}`;
    if(filter.usuario) params+= `&usuario=${filter.usuario}`;
    if(filter.usuarioPadre) params+= `&usuarioPadre=${filter.usuarioPadre}`;

    // if(filter.gatherer) params+= `&gatherer=${filter.gatherer}`;
    // if(filter.producer) params+= `&producer=${filter.producer}`;
    params +=`&registros=${pageSize}`;
    params = params +`&paginaActual=${paginaActual}`;
    return this.http.get(this.url + params)
  }

  searchEstablecimientos(filter: any, pageSize, paginaActual): Observable<any> {
    let params = '?_sort=id&_order=desc';
    if(filter.usuario) params+= `&usuario=${filter.usuario}`;
    if(filter.usuarioPadre) params+= `&usuarioPadre=${filter.usuarioPadre}`;

    params +=`&registros=${pageSize}`;
    params = params +`&paginaActual=${paginaActual}`;
    return this.http.get(this.url+'/establecimientos' + params)
  }

  remove(id: any): Observable<any> {
    return this.http.delete(`${this.url}/${id}`)
  }
  removeMultiple(ids:number[]): Observable<any>{
    return this.http.post<any>(`${this.url}/deleteMultiple`, {ids:ids}, { observe: 'response' });
  }

  copiarPlanes(idCampania: number, idProductor?:number): Observable<any> {
    return this.http.put(`${this.url}/copiarPlanes/${idCampania}/${idProductor}`,{});

  }
  aprobarMultiple(ids:number[]): Observable<any>{
    return this.http.put<any>(`${this.url}/aprobarMultiple`, {ids:ids}, { observe: 'response' });
  }
}
